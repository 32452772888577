.page {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	font-size: 30;
}

.title {
	color: var(--color-primary);
	font-size: 30px;
	font-weight: bold;
	text-align: center;
	padding: 0 1em;
}
