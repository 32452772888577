.app:global(.dark) {
	/* https://www.colourlovers.com/palette/72905/iam_approachingnight */
	--color-primary: #f2e982;
	--color-secondary: #f0c76f;
	--color-error: #eb1b3b;
	--color-background: #2b3036;
}

.app {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: var(--color-background);
}

.phoneWrapper {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--color-background);
	overflow: hidden;
}

.phoneWrapperContents {
	height: 100%;
	width: 100%;
	overflow: hidden;
	background-color: #fff;
}

@media screen and (min-width: 600px) {
	.phoneWrapperContents {
		width: 360px;
		height: 600px;
	}
}
